import React from 'react';
import AuthWrapper from '../../components/layout/AuthWrapper';
import Head from '../../components/layout/Head';
import Layout from '../../components/layout/Layout';
import FillForm from '../../components/organisms/Form/FillForm';

const CompleteForm = () => {
  return (
    <AuthWrapper userRole="all">
      <Head title="Review Learner Form" />
      <Layout
        hideSidebar
        breadcrumbs={[{ link: '/', label: 'Home' }, { label: 'Complete form' }]}
      >
        <FillForm userType="employer" />
      </Layout>
    </AuthWrapper>
  );
};

export default CompleteForm;
